import { ref, watch } from "vue";
import { apiEgpmTaskResultTopBrand, apiEgpmTaskResultTopShop, apiEgpmTaskResultTopBrand2, apiEgpmTaskResultTopShop2 } from "@/request/egpmApi";
import echarts from "@/utils/echarts";

// 为字符串换行
function addNewLines(str, len) {
    let result = '';
    for (let i = 0; i < str.length; i += len) {
        // 截取当前片段，注意使用slice方法，第二个参数是结束位置的下一个字符的索引  
        // 如果i+len超过了字符串的长度，slice会自动处理并只返回剩余的部分  
        let segment = str.slice(i, i + len);
        // 将当前片段添加到结果字符串中，并在其后添加一个换行符（除了最后一个片段）  
        result += segment;
        // 检查是否还有剩余的字符串需要处理，如果有，则添加换行符  
        if (i + len < str.length) {
            result += '\n';
        }
    }
    return result;
}

export default ({ props, route, batch }) => {

    const labelCount = 8;// label每行字数
    let brandChart = null;// top3品牌
    let shopChart = null;// top3门店
    let brandAllChart = null;// top3品牌-全菜系
    let shopAllChart = null; // top3门店-全菜系
    const brandLoading = ref(false);
    const shopLoading = ref(false);
    const brandAllLoading = ref(false);
    const shopAllLoading = ref(false);

    // 获取top3品牌
    async function getTopBrandData() {
        if (brandLoading.value) return;
        brandLoading.value = true;
        try {
            const params = {
                taskId: route.query.id,
                batch: batch.value || '',
                dishName: props.dish.dishName,
            }
            const { data } = await apiEgpmTaskResultTopBrand(params);
            initBrandChart(data?.slice(0, 3) || []);
        } finally {
            brandLoading.value = false;
        }
    }

    // 获取top3门店
    async function getTopShopData() {
        if (shopLoading.value) return;
        shopLoading.value = true;
        try {
            const params = {
                taskId: route.query.id,
                batch: batch.value || '',
                dishName: props.dish.dishName,
            }
            const { data } = await apiEgpmTaskResultTopShop(params);
            initShopChart(data?.slice(0, 3) || []);
        } finally {
            shopLoading.value = false;
        }
    }

    // 获取top3品牌-全菜系
    async function getTopBrandAllData() {
        if (brandAllLoading.value) return;
        brandAllLoading.value = true;
        try {
            const params = {
                taskId: route.query.id,
                batch: batch.value || '',
                dishName: props.dish.dishName,
            }
            const { data } = await apiEgpmTaskResultTopBrand2(params);
            initBrandAllChart(data?.slice(0, 3) || []);
        } finally {
            brandAllLoading.value = false;
        }
    }

    // 获取top3门店-全菜系
    async function getTopShopAllData() {
        if (shopAllLoading.value) return;
        shopAllLoading.value = true;
        try {
            const params = {
                taskId: route.query.id,
                batch: batch.value || '',
                dishName: props.dish.dishName,
            }
            const { data } = await apiEgpmTaskResultTopShop2(params);
            initShopAllChart(data?.slice(0, 3) || []);
        } finally {
            shopAllLoading.value = false;
        }
    }

    // resize
    function handleResize() {
        brandChart && brandChart.resize();
        shopChart && shopChart.resize();
        brandAllChart && brandAllChart.resize();
        shopAllChart && shopAllChart.resize();
    }

    // top3品牌
    function initBrandChart(data = []) {
        const chartDom = document.getElementById("top_brand_chart");
        brandChart = echarts.init(chartDom);
        data = data.reverse();
        const categoryData = data.map(item => item?.name);
        const seriesData = data.map(item => item?.value);
        const option = {
            title: {
                text: "TOP3品牌" + `(${batch.value})`,
            },
            tooltip: {
                trigger: "axis",
            },
            grid: {
                left: 5,
                bottom: 30,
                containLabel: true,
            },
            xAxis: {
                type: "value",
            },
            yAxis: {
                type: "category",
                data: categoryData,
                axisLabel: {
                    interval: 0,
                    formatter: (val) => {
                        return addNewLines(val, labelCount);
                    }
                }
            },
            series: [
                {
                    type: "bar",
                    barWidth: 30,
                    label: {
                        show: true,
                        position: 'right',
                        formatter: (e) => {
                            if (e?.value?.toString().includes(".")) {
                                return e?.value?.toFixed(2);
                            }
                            return e?.value;
                        }
                    },
                    data: seriesData,
                },
            ],
        };
        // resize
        window.addEventListener("resize", handleResize);

        option && brandChart.setOption(option);
    }

    // top3门店
    function initShopChart(data = []) {
        const chartDom = document.getElementById("top_shop_chart");
        shopChart = echarts.init(chartDom);
        data = data.reverse();
        const categoryData = data.map(item => item?.name);
        const seriesData = data.map(item => item?.value);
        const option = {
            title: {
                text: "TOP3门店" + `(${batch.value})`,
            },
            tooltip: {
                trigger: "axis",
            },
            grid: {
                left: 5,
                bottom: 30,
                containLabel: true,
            },
            xAxis: {
                type: "value",
            },
            yAxis: {
                type: "category",
                data: categoryData,
                axisLabel: {
                    interval: 0,
                    formatter: (val) => {
                        return addNewLines(val, labelCount);
                    }
                }
            },
            series: [
                {
                    type: "bar",
                    barWidth: 30,
                    label: {
                        show: true,
                        position: 'right',
                        formatter: (e) => {
                            if (e?.value?.toString().includes(".")) {
                                return e?.value?.toFixed(2);
                            }
                            return e?.value;
                        }
                    },
                    data: seriesData,
                },
            ],
        };
        // resize
        window.addEventListener("resize", handleResize);

        option && shopChart.setOption(option);
    }

    // top3品牌-全菜系
    function initBrandAllChart(data = []) {
        const chartDom = document.getElementById("top_brand_chart_all");
        brandAllChart = echarts.init(chartDom);
        data = data.reverse();
        const categoryData = data.map(item => item?.name + `(${item.attr})`);
        const seriesData = data.map(item => item?.value);
        const option = {
            title: {
                text: "TOP3品牌【全菜系】" + `(${batch.value})`,
            },
            tooltip: {
                trigger: "axis",
            },
            grid: {
                left: 5,
                bottom: 30,
                containLabel: true,
            },
            xAxis: {
                type: "value",
            },
            yAxis: {
                type: "category",
                data: categoryData,
                axisLabel: {
                    interval: 0,
                    formatter: (val) => {
                        return addNewLines(val, labelCount);
                    }
                }
            },
            series: [
                {
                    type: "bar",
                    barWidth: 30,
                    label: {
                        show: true,
                        position: 'right',
                        formatter: (e) => {
                            if (e?.value?.toString().includes(".")) {
                                return e?.value?.toFixed(2);
                            }
                            return e?.value;
                        }
                    },
                    data: seriesData,
                },
            ],
        };
        // resize
        window.addEventListener("resize", handleResize);

        option && brandAllChart.setOption(option);
    }

    // top3门店-全菜系
    function initShopAllChart(data = []) {
        const chartDom = document.getElementById("top_shop_chart_all");
        shopAllChart = echarts.init(chartDom);
        data = data.reverse();
        const categoryData = data.map(item => item?.name + `(${item.attr})`);
        const seriesData = data.map(item => item?.value);
        const option = {
            title: {
                text: "TOP3门店【全菜系】" + `(${batch.value})`,
            },
            tooltip: {
                trigger: "axis",
            },
            grid: {
                left: 5,
                bottom: 30,
                containLabel: true,
            },
            xAxis: {
                type: "value",
            },
            yAxis: {
                type: "category",
                data: categoryData,
                axisLabel: {
                    interval: 0,
                    formatter: (val) => {
                        return addNewLines(val, labelCount);
                    }
                }
            },
            series: [
                {
                    type: "bar",
                    barWidth: 30,
                    label: {
                        show: true,
                        position: 'right',
                        formatter: (e) => {
                            if (e?.value?.toString().includes(".")) {
                                return e?.value?.toFixed(2);
                            }
                            return e?.value;
                        }
                    },
                    data: seriesData,
                },
            ],
        };
        // resize
        window.addEventListener("resize", handleResize);

        option && shopAllChart.setOption(option);
    }

    // 处理销毁
    function handleTopDispose() {
        window.removeEventListener("resize", handleResize);
        brandChart && brandChart.dispose();// top3品牌
        brandChart = null;
        shopChart && shopChart.dispose();// top3门店
        shopChart = null;
        brandAllChart && brandAllChart.dispose();// top3品牌-全菜系
        brandAllChart = null;
        shopAllChart && shopAllChart.dispose();// top3门店-全菜系
        shopAllChart = null;
        brandLoading.value = false;
        shopLoading.value = false;
        brandAllLoading.value = false;
        shopAllLoading.value = false;
    }

    watch(() => batch.value, () => {
        if (!props.show) return;
        handleTopDispose();
        getTopBrandData();// top3品牌
        getTopShopData();// top3门店
        getTopBrandAllData();// top3品牌-全菜系
        getTopShopAllData();// top3门店-全菜系
    })

    return {
        brandLoading,
        shopLoading,
        brandAllLoading,
        shopAllLoading,
        handleTopDispose
    }
}