<template>
    <el-dialog :model-value="show" :title="title + '-' + dish.name" width="90%" top="5vh" :close-on-click-modal="false" @close="handleClose" @opened="handleOpened">
        <div class="details_dialog_content" v-loading="loading" element-loading-background="rgba(255,255,255,.4)">
            <div class="chart">
                <div class="dish_chart_box" id="dish_chart_box"></div>
                <div class="right_box">
                    <div class="top_box" id="top_brand_chart"></div>
                    <div class="top_box" id="top_shop_chart"></div>
                </div>
                <div class="right_box">
                    <div class="top_box" id="top_brand_chart_all"></div>
                    <div class="top_box" id="top_shop_chart_all"></div>
                </div>
            </div>
        </div>
    </el-dialog>
</template>

<script setup>
import { useRoute } from "vue-router";
import { ref, computed } from "vue";
import useLineChart from "./useLineChart";
import useTopChart from "./useTopChart";

const route = useRoute();
const props = defineProps({
    show: Boolean,
    title: { type: String, default: "" },
    dish: { type: Object, default: () => {} },
    chartData: { type: Object, default: () => {} },
});
const emits = defineEmits(["close"]);
const batch = ref(""); // 折线图选中的batch
const loading = computed(() => {
    const loadingList = [leLoading.value, brandLoading.value, shopLoading.value, brandAllLoading.value, shopAllLoading.value];
    return loadingList.some((item) => item);
});

// 折线图
const { leLoading, getData, handleLineDispose } = useLineChart({ props, route, batch });

// 右侧柱状图
const { brandLoading, shopLoading, brandAllLoading, shopAllLoading, handleTopDispose } = useTopChart({ props, route, batch });

function handleOpened() {
    getData();
}

function handleClose() {
    handleLineDispose();
    handleTopDispose();
    batch.value = "";
    emits("close");
}
</script>

<style lang="scss" scoped>
.details_dialog_content {
    .btn_box {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 30px;
    }
    .chart {
        display: flex;
        height: 600px;
        .dish_chart_box {
            width: 40%;
            height: 100%;
        }
        .right_box {
            width: 30%;
            height: 100%;
            .top_box {
                height: 50%;
            }
        }
    }
}
</style>
