<template>
    <table>
        <thead>
            <tr>
                <th></th>
                <th>E组</th>
                <th>G组</th>
                <th>P组</th>
                <th>M组</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>预测准确率</td>
                <td>-</td>
                <td>{{ cdata.GExactRatio }}</td>
                <td>{{ cdata.PExactRatio }}</td>
                <td>{{ cdata.MExactRatio }}</td>
            </tr>
            <tr>
                <td>菜品数量</td>
                <td>{{ cdata.EDishNum }}</td>
                <td>{{ cdata.GDishNum }}</td>
                <td>{{ cdata.PDishNum }}</td>
                <td>{{ cdata.MDishNum }}</td>
            </tr>
            <tr>
                <td>菜品数占比</td>
                <td>{{ cdata.EDishNumRatio }}</td>
                <td>{{ cdata.GDishNumRatio }}</td>
                <td>{{ cdata.PDishNumRatio }}</td>
                <td>{{ cdata.MDishNumRatio }}</td>
            </tr>
            <tr>
                <td>门店数量</td>
                <td>{{ cdata.EShopNum }}</td>
                <td>{{ cdata.GShopNum }}</td>
                <td>{{ cdata.PShopNum }}</td>
                <td>{{ cdata.MShopNum }}</td>
            </tr>
            <tr>
                <td>门店数占比</td>
                <td>{{ cdata.EShopNumRatio }}</td>
                <td>{{ cdata.GShopNumRatio }}</td>
                <td>{{ cdata.PShopNumRatio }}</td>
                <td>{{ cdata.MShopNumRatio }}</td>
            </tr>
            <tr>
                <td>推荐数</td>
                <td>{{ cdata.ERecommendsNum }}</td>
                <td>{{ cdata.GRecommendsNum }}</td>
                <td>{{ cdata.PRecommendsNum }}</td>
                <td>{{ cdata.MRecommendsNum }}</td>
            </tr>
            <tr>
                <td>推荐数占比</td>
                <td>{{ cdata.ERecommendsNumRatio }}</td>
                <td>{{ cdata.GRecommendsNumRatio }}</td>
                <td>{{ cdata.PRecommendsNumRatio }}</td>
                <td>{{ cdata.MRecommendsNumRatio }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script setup>
import { computed } from "vue";

const props = defineProps({
    data: { type: Object, default: {} },
});
const cdata = computed(() => {
    const res = {};
    for (const key in props.data) {
        if (key.endsWith("Ratio")) {
            if (typeof props.data[key] === "number") {
                if (props.data[key] !== 0) {
                    res[key] = (props.data[key] * 100).toFixed(2) + "%";
                } else {
                    res[key] = props.data[key];
                }
            } else {
                res[key] = "-";
            }
        } else {
            res[key] = props.data[key];
        }
    }
    return res;
});
</script>

<style lang="scss" scoped>
table {
    width: 100%;
    border: 1px solid #f2f2f2;
    border-collapse: collapse;
    th,
    td {
        border: 1px solid #f2f2f2;
        width: 20%;
        text-align: center;
        padding: 12px 0;
        font-size: 14px;
        color: #666;
        &:first-child {
            font-weight: bold;
        }
    }
    th {
        font-weight: bold;
    }
}
</style>
