export default () => {

    // 需要头部加粗的字段
    const blodProperty = [
        'dishName',// 菜品名称
        'batch',// batch
        'flag',// EGPM指标
        'totalScore',// 总得分
        'distributeScore',// 扩散度总得分
        'growthScore',// 成长性总得分
        'hotScore',// 热度总得分
    ]

    // 黄色背景单元格字段 FEF2CC
    const yellowProperty = [
        'flag',// EGPM指标
        'egpmShopNumScore',// EGPM门店得分
        'egpmRecommendsNumScore',// EGPM推荐数得分
        'batchTotal',// 连续期数
        'trend',// 菜品连续指数连续趋势
        'shopRatio',// 门店渗透率
    ]

    // 蓝色背景单元格字段 D9E2F3
    const blueProperty = [
        'shopNum',// 门店数
        'brandNum',// 品牌数
        'provinceNum',// 省份数
        'cityNum',// 城市数
        'recommendsNum',// 推荐总数
        'avgRecommendsNum',// 店均推荐数
        'provinceNumInc',// 省份数增长率
        'cityNumInc',// 城市数增长率
        'recommendsNumInc',// 推荐总数增长率
        'avgRecommendsNumInc',// 店均推荐数增长率
    ]

    // 表格头部样式
    function headerCellStyle({ column }) {
        const res = {
            fontWeight: 'normal',
            background: '#f2f2f2',
            borderColor: '#ccc',
            color: '#666'
        }
        // 是否加粗
        if (blodProperty.includes(column.property)) {
            res.fontWeight = 'bold';
            res.color = '#000';
        }
        // 黄色背景
        if (yellowProperty.includes(column.property)) {
            res.background = '#FEF2CC';
        }
        // 蓝色背景
        if (blueProperty.includes(column.property)) {
            res.background = '#D9E2F3';
        }
        return res;
    }

    // 单元格样式
    function cellStyle({ column }) {
        const res = {
            background: '#fff',
            borderColor: '#ccc',
            height: '57px'
        }
        // 黄色背景
        if (yellowProperty.includes(column.property)) {
            res.background = '#FEF2CC';
        }
        // 蓝色背景
        if (blueProperty.includes(column.property)) {
            res.background = '#D9E2F3';
        }
        return res;
    }

    return { headerCellStyle, cellStyle }
}